// src/layouts/MainLayout.js
import { useState } from 'react';
import { Outlet, Link, useLocation, Navigate } from 'react-router-dom';
import { 
  BarChart2, Calendar, Users, FileText, Briefcase, MessageSquare, Search, 
  Menu, X, ChevronDown, Settings, LogOut, User, Bell, Grid3X3, Handshake, BookOpen,
  CheckSquare, CheckCircle, Database, Bot, FileSpreadsheet, Target // Add this import for the monitoring icon
} from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import logo from '../assets/images/logo.png'; 

const MainLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(window.innerWidth >= 768);
  const [profileOpen, setProfileOpen] = useState(false);
  const { currentUser, userProfile, signOut, loading } = useAuth();
  const location = useLocation();
  
  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);
  const toggleProfile = () => setProfileOpen(!profileOpen);
  
  // Redirect to login if not authenticated
  if (!loading && !currentUser) {
    return <Navigate to="/login" />;
  }
  
  // Redirect to create profile if authenticated but no profile
  if (!loading && currentUser && !userProfile) {
    return <Navigate to="/create-profile" />;
  }
  
  // Show loading state while checking auth
  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }
  
  const navigation = [
    { name: 'Dashboard', icon: BarChart2, path: '/dashboard' },
    { name: 'AI Chat', icon: Bot, path: '/ai-chat' },
    { name: 'Records', icon: Database, path: '/records' },
    { name: 'Messages', icon: MessageSquare, path: '/communication' },
    { name: 'Planner', icon: Target, path: '/planner' },  
    { name: 'Tasks', icon: CheckCircle, path: '/tasks' },
    { name: 'Meetings', icon: Calendar, path: '/meetings' },
    { name: 'Documents', icon: FileText, path: '/documents' },
    { name: 'Resources', icon: Briefcase, path: '/resources' },
    { name: 'Structures', icon: Grid3X3, path: '/structures' },
    { name: 'Stakeholders', icon: Handshake, path: '/stakeholders' },
    { name: 'Subjects', icon: BookOpen, path: '/subjects' },
    { name: 'Institutions', icon: BookOpen, path: '/institutions' },
    { name: 'Monitoring', icon: CheckSquare, path: '/monitoring' },
    { name: 'Staff', icon: Users, path: '/staff' },
  ];
  
  // Get initials for avatar
  const getInitials = () => {
    if (userProfile) {
      return `${userProfile.firstName?.charAt(0) || ''}${userProfile.lastName?.charAt(0) || ''}`;
    }
    return currentUser?.email?.charAt(0).toUpperCase() || 'U';
  };
  
  const getUserFullName = () => {
    if (userProfile) {
      return `${userProfile.title || ''} ${userProfile.firstName || ''} ${userProfile.lastName || ''}`.trim();
    }
    return currentUser?.email || 'User';
  };
  
  const handleSignOut = async () => {
    try {
      await signOut();
      // The auth listener should automatically redirect to login
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };
  
  return (
    <div className="flex h-screen bg-gray-50 text-gray-800">
      {/* Sidebar - now toggleable on desktop as well */}
      <div className={`fixed md:static inset-y-0 left-0 z-50 transform ${
        sidebarOpen ? 'translate-x-0' : '-translate-x-full'
      } transition-transform duration-300 ease-in-out md:translate-x-0 bg-white shadow-lg w-64 flex flex-col ${
        !sidebarOpen && 'md:w-0 md:overflow-hidden'
      }`}>
        <div className="flex items-center justify-between px-4 h-16 border-b">
          <div className="flex items-center">
             <Link to="/about" className="flex-shrink-0 flex items-center">
                <img
                               className="h-8 w-auto"
                               src={logo}
                               alt="Schoolmate Logo"
                             />
                           </Link>
            </div>
          <button onClick={toggleSidebar} className="text-gray-500 hover:text-gray-700">
            <X size={20} />
          </button>
        </div>
        
        <nav className="flex-1 py-4 overflow-y-auto">
          <ul className="px-2 space-y-1">
          {navigation.map((item) => (
            <li key={item.name}>
              <Link
                to={item.path}
                className={`flex items-center w-full px-4 py-3 rounded-md ${
                  location.pathname === item.path || location.pathname.startsWith(`${item.path}/`)
                    ? 'bg-[#1d67cd]/10 text-[#1d67cd]' 
                    : 'text-gray-700 hover:bg-gray-100'
                }`}
                onClick={() => {
                  // Close sidebar on mobile when a menu item is clicked
                  if (window.innerWidth < 768) {
                    setSidebarOpen(false);
                  }
                }}
              >
                <item.icon size={20} className="mr-3" />
                <span>{item.name}</span>
              </Link>
            </li>
          ))}
          </ul>
        </nav>
        
        {/* User info at bottom of sidebar */}
        {userProfile && (
          <div className="border-t p-4">
            <div className="flex items-center">
              <div className="w-10 h-10 rounded-full bg-blue-100 flex items-center justify-center text-blue-600 font-medium">
                {getInitials()}
              </div>
              <div className="ml-3">
                <p className="text-sm font-medium text-gray-800">{getUserFullName()}</p>
                <p className="text-xs text-gray-500">{userProfile.userRole}</p>
              </div>
            </div>
          </div>
        )}
      </div>
      
      {/* Main Content */}
      <div className="flex-1 flex flex-col overflow-hidden m-0 sm:mx-4">
        {/* Topbar */}
        <header className="h-16 bg-white border-b flex items-center justify-between px-4 md:px-6">
          <div className="flex items-center">
            <button onClick={toggleSidebar} className="mr-4 text-gray-500 hover:text-gray-700">
              <Menu size={24} />
            </button>
            <div className="relative">
              <Search size={18} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input 
                type="text" 
                placeholder="Lamontville High School" 
                className="pl-10 pr-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 w-full md:w-96" 
              />
            </div>
          </div>
          
          <div className="flex items-center space-x-4">
            <button className="relative text-gray-500 hover:text-gray-700">
              <Bell size={20} />
              <span className="absolute top-0 right-0 w-2 h-2 bg-red-500 rounded-full"></span>
            </button>
            
            <div className="relative">
              <button 
                onClick={toggleProfile}
                className="flex items-center space-x-2 text-gray-700 hover:text-gray-900 focus:outline-none"
              >
                <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center text-blue-600 font-medium">
                  {getInitials()}
                </div>
                <span className="hidden md:block">{getUserFullName()}</span>
                <ChevronDown size={16} />
              </button>
              
              {profileOpen && (
                <div className="absolute right-0 mt-2 w-48 py-2 bg-white rounded-md shadow-xl z-50">
                  <Link 
                  to="/profile" 
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center"
                >
                  <User size={16} className="mr-2" />
                  <span>Profile</span>
                </Link>
                <Link 
                  to="settings" 
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center"
                >
                  <Settings size={16} className="mr-2" />
                  <span>Settings</span>
                </Link>
                  <button 
                    onClick={handleSignOut}
                    className="w-full text-left block px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center"
                  >
                    <LogOut size={16} className="mr-2" />
                    <span>Log out</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </header>
        
        {/* Content Area */}
        <main className="flex-1 overflow-y-auto p-4 md:p-2 bg-[#f7f7f7]">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default MainLayout;