import React, { useState, useEffect } from 'react';
import { 
  Bell, Calendar, Users, FileText, Briefcase, BarChart2, 
  MessageSquare, Search, Menu, X, ChevronDown, Settings, 
  LogOut, User, AlertCircle, Check, Book, Award
} from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import { db } from '../firebase/config';
import { 
  collection, query, where, orderBy, limit, 
  getDocs, Timestamp 
} from 'firebase/firestore';
import { format, subDays, startOfWeek, endOfWeek, differenceInDays } from 'date-fns';

const Dashboard = () => {
  const { userProfile } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [usingMockData, setUsingMockData] = useState(false);
  const [dashboardData, setDashboardData] = useState({
    stats: {
      upcomingMeetings: 0,
      meetingsChange: '',
      resourceUtilization: '',
      utilizationChange: '',
      documentWorkflows: 0,
      workflowsStatus: '',
      activeStaff: 0,
      staffBreakdown: ''
    },
    attendance: {
      previousDay: 0,
      previousWeek: 0,
      yearAverage: 0,
      trend: ''
    },
    subjects: {
      performance: []
    },
    tasks: {
      inProgress: 0,
      completed: 0,
      overdue: 0
    },
    teachingLearning: {
      expected: 0,
      actual: 0,
      percentage: 0
    },
    assessments: {
      formal: {
        expected: 0,
        actual: 0,
        percentage: 0
      },
      informal: {
        expected: 0,
        actual: 0,
        percentage: 0
      }
    },
    recentActivity: [],
    aiInsights: [],
    upcomingMeetings: []
  });

  // Fetch dashboard data from Firestore
  useEffect(() => {
    const fetchDashboardData = async () => {
      setIsLoading(true);
      let useMockData = false;
      let newDashboardData = { ...dashboardData };

      try {
        if (userProfile?.institutionId) {
          // Fetch attendance data
          const attendanceData = await fetchAttendanceData(userProfile.institutionId);
          if (attendanceData) {
            newDashboardData.attendance = attendanceData;
          } else {
            useMockData = true;
          }

          // Fetch subjects performance data
          const subjectsData = await fetchSubjectsData(userProfile.institutionId);
          if (subjectsData && subjectsData.length > 0) {
            newDashboardData.subjects.performance = subjectsData;
          } else {
            useMockData = true;
          }

          // Fetch tasks data
          const tasksData = await fetchTasksData(userProfile.institutionId);
          if (tasksData) {
            newDashboardData.tasks = tasksData;
          } else {
            useMockData = true;
          }

          // Fetch teaching and learning data
          const teachingData = await fetchTeachingData(userProfile.institutionId);
          if (teachingData) {
            newDashboardData.teachingLearning = teachingData;
          } else {
            useMockData = true;
          }

          // Fetch assessments data
          const assessmentsData = await fetchAssessmentsData(userProfile.institutionId);
          if (assessmentsData) {
            newDashboardData.assessments = assessmentsData;
          } else {
            useMockData = true;
          }

          // Fetch recent activity
          const recentActivity = await fetchRecentActivity(userProfile.institutionId);
          if (recentActivity && recentActivity.length > 0) {
            newDashboardData.recentActivity = recentActivity;
          } else {
            useMockData = true;
          }

          // Fetch AI insights
          const insights = await fetchAIInsights(userProfile.institutionId);
          if (insights && insights.length > 0) {
            newDashboardData.aiInsights = insights;
          } else {
            useMockData = true;
          }

          // Fetch upcoming meetings
          const meetings = await fetchUpcomingMeetings(userProfile.institutionId);
          if (meetings && meetings.length > 0) {
            newDashboardData.upcomingMeetings = meetings;
          } else {
            useMockData = true;
          }

          // Fetch general stats
          const stats = await fetchGeneralStats(userProfile.institutionId);
          if (stats) {
            newDashboardData.stats = stats;
          } else {
            useMockData = true;
          }
        } else {
          useMockData = true;
        }
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        useMockData = true;
      }

      // If we need to use mock data for any section, fill in the missing parts
      if (useMockData) {
        newDashboardData = {
          ...newDashboardData,
          ...getMockDashboardData(newDashboardData)
        };
        setUsingMockData(true);
      }

      setDashboardData(newDashboardData);
      setIsLoading(false);
    };

    fetchDashboardData();
  }, [userProfile]);

  // Fetch attendance data from records
  const fetchAttendanceData = async (institutionId) => {
    try {
      const recordsRef = collection(db, "records");
      
      // Get yesterday's date
      const yesterday = subDays(new Date(), 1);
      const yesterdayStart = new Date(yesterday);
      yesterdayStart.setHours(0, 0, 0, 0);
      const yesterdayEnd = new Date(yesterday);
      yesterdayEnd.setHours(23, 59, 59, 999);

      // Query for previous day attendance records
      const yesterdayQuery = query(
        recordsRef,
        where("institutionId", "==", institutionId),
        where("type", "==", "Attendance"),
        where("date", ">=", yesterdayStart),
        where("date", "<=", yesterdayEnd)
      );

      const yesterdaySnapshot = await getDocs(yesterdayQuery);
      
      // Process yesterday's attendance
      let totalYesterday = 0;
      let presentYesterday = 0;
      
      yesterdaySnapshot.forEach(doc => {
        const data = doc.data();
        if (data.total) totalYesterday += parseInt(data.total);
        if (data.present) presentYesterday += parseInt(data.present);
      });
      
      const previousDayAttendance = totalYesterday > 0 ? 
        Math.round((presentYesterday / totalYesterday) * 100) : 0;

      // Get last week's date range
      const lastWeekStart = startOfWeek(subDays(new Date(), 7));
      const lastWeekEnd = endOfWeek(subDays(new Date(), 7));

      // Query for previous week attendance
      const weekQuery = query(
        recordsRef,
        where("institutionId", "==", institutionId),
        where("type", "==", "Attendance"),
        where("date", ">=", lastWeekStart),
        where("date", "<=", lastWeekEnd)
      );

      const weekSnapshot = await getDocs(weekQuery);
      
      // Process weekly attendance
      let totalWeek = 0;
      let presentWeek = 0;
      
      weekSnapshot.forEach(doc => {
        const data = doc.data();
        if (data.total) totalWeek += parseInt(data.total);
        if (data.present) presentWeek += parseInt(data.present);
      });
      
      const previousWeekAttendance = totalWeek > 0 ? 
        Math.round((presentWeek / totalWeek) * 100) : 0;

      // Query for year-to-date attendance
      const yearStart = new Date(new Date().getFullYear(), 0, 1);
      const yearQuery = query(
        recordsRef,
        where("institutionId", "==", institutionId),
        where("type", "==", "Attendance"),
        where("date", ">=", yearStart)
      );

      const yearSnapshot = await getDocs(yearQuery);
      
      // Process yearly attendance
      let totalYear = 0;
      let presentYear = 0;
      
      yearSnapshot.forEach(doc => {
        const data = doc.data();
        if (data.total) totalYear += parseInt(data.total);
        if (data.present) presentYear += parseInt(data.present);
      });
      
      const yearAverageAttendance = totalYear > 0 ? 
        Math.round((presentYear / totalYear) * 100) : 0;

      // Calculate trend
      let trend = 'stable';
      if (previousDayAttendance > previousWeekAttendance + 5) {
        trend = 'up';
      } else if (previousDayAttendance < previousWeekAttendance - 5) {
        trend = 'down';
      }

      // If we have meaningful data (at least some records), return it
      if (yesterdaySnapshot.size > 0 || weekSnapshot.size > 0 || yearSnapshot.size > 0) {
        return {
          previousDay: previousDayAttendance,
          previousWeek: previousWeekAttendance,
          yearAverage: yearAverageAttendance,
          trend
        };
      }
    } catch (error) {
      console.error('Error fetching attendance data:', error);
    }
    
    return null;
  };

  // Fetch subjects performance data
  const fetchSubjectsData = async (institutionId) => {
    try {
      const subjectsRef = collection(db, "subjects");
      const q = query(
        subjectsRef,
        where("institutionId", "==", institutionId),
        limit(10)
      );

      const snapshot = await getDocs(q);
      
      if (snapshot.empty) return null;

      // Process subjects and their performance
      const subjects = [];
      snapshot.forEach(doc => {
        const data = doc.data();
        if (data.name && data.performance) {
          subjects.push({
            name: data.name,
            performance: data.performance || Math.floor(Math.random() * 40) + 60, // Use random as fallback
            grade: data.grade || 'Unknown'
          });
        }
      });

      return subjects;
    } catch (error) {
      console.error('Error fetching subjects data:', error);
      return null;
    }
  };

  // Fetch tasks data
  const fetchTasksData = async (institutionId) => {
    try {
      const tasksRef = collection(db, "tasks");
      const q = query(
        tasksRef,
        where("institutionId", "==", institutionId)
      );

      const snapshot = await getDocs(q);
      
      if (snapshot.empty) return null;

      // Process tasks data
      let inProgress = 0;
      let completed = 0;
      let overdue = 0;
      const now = new Date();

      snapshot.forEach(doc => {
        const task = doc.data();
        
        if (task.status === 'completed') {
          completed++;
        } else if (task.status === 'in_progress') {
          inProgress++;
          
          // Check if overdue
          if (task.dueDate && task.dueDate.toDate() < now) {
            overdue++;
          }
        }
      });

      return {
        inProgress,
        completed,
        overdue
      };
    } catch (error) {
      console.error('Error fetching tasks data:', error);
      return null;
    }
  };

  // Fetch teaching and learning data
  const fetchTeachingData = async (institutionId) => {
    try {
      const recordsRef = collection(db, "records");
      const yearStart = new Date(new Date().getFullYear(), 0, 1);
      
      const q = query(
        recordsRef,
        where("institutionId", "==", institutionId),
        where("type", "==", "Teaching"),
        where("date", ">=", yearStart)
      );

      const snapshot = await getDocs(q);
      
      if (snapshot.empty) return null;

      // Process teaching records
      let totalExpectedHours = 0;
      let totalActualHours = 0;

      snapshot.forEach(doc => {
        const data = doc.data();
        if (data.expected) totalExpectedHours += parseFloat(data.expected);
        if (data.actual) totalActualHours += parseFloat(data.actual);
      });

      const percentage = totalExpectedHours > 0 ? 
        Math.round((totalActualHours / totalExpectedHours) * 100) : 0;

      return {
        expected: totalExpectedHours,
        actual: totalActualHours,
        percentage
      };
    } catch (error) {
      console.error('Error fetching teaching data:', error);
      return null;
    }
  };

  // Fetch assessments data
  const fetchAssessmentsData = async (institutionId) => {
    try {
      const recordsRef = collection(db, "records");
      const yearStart = new Date(new Date().getFullYear(), 0, 1);
      
      // Query for formal assessments
      const formalQuery = query(
        recordsRef,
        where("institutionId", "==", institutionId),
        where("type", "==", "Assessment"),
        where("category", "==", "Formal"),
        where("date", ">=", yearStart)
      );

      const formalSnapshot = await getDocs(formalQuery);
      
      // Process formal assessments
      let formalExpected = 0;
      let formalActual = 0;

      formalSnapshot.forEach(doc => {
        const data = doc.data();
        if (data.expected) formalExpected += parseInt(data.expected);
        if (data.actual) formalActual += parseInt(data.actual);
      });

      const formalPercentage = formalExpected > 0 ? 
        Math.round((formalActual / formalExpected) * 100) : 0;

      // Query for informal assessments
      const informalQuery = query(
        recordsRef,
        where("institutionId", "==", institutionId),
        where("type", "==", "Assessment"),
        where("category", "==", "Informal"),
        where("date", ">=", yearStart)
      );

      const informalSnapshot = await getDocs(informalQuery);
      
      // Process informal assessments
      let informalExpected = 0;
      let informalActual = 0;

      informalSnapshot.forEach(doc => {
        const data = doc.data();
        if (data.expected) informalExpected += parseInt(data.expected);
        if (data.actual) informalActual += parseInt(data.actual);
      });

      const informalPercentage = informalExpected > 0 ? 
        Math.round((informalActual / informalExpected) * 100) : 0;

      // If we have meaningful data, return it
      if (formalSnapshot.size > 0 || informalSnapshot.size > 0) {
        return {
          formal: {
            expected: formalExpected,
            actual: formalActual,
            percentage: formalPercentage
          },
          informal: {
            expected: informalExpected,
            actual: informalActual,
            percentage: informalPercentage
          }
        };
      }
    } catch (error) {
      console.error('Error fetching assessments data:', error);
    }
    
    return null;
  };

  // Fetch recent activity
  const fetchRecentActivity = async (institutionId) => {
    try {
      const activityRef = collection(db, "activityLogs");
      const q = query(
        activityRef,
        where("institutionId", "==", institutionId),
        orderBy("timestamp", "desc"),
        limit(5)
      );

      const snapshot = await getDocs(q);
      
      if (snapshot.empty) return null;

      // Process activity logs
      const activities = [];
      snapshot.forEach(doc => {
        const data = doc.data();
        activities.push({
          id: doc.id,
          user: {
            initials: data.userInitials || 'UN',
            name: data.userName || 'Unknown User',
            color: data.userColor || 'blue'
          },
          action: data.action,
          time: data.timestamp ? format(data.timestamp.toDate(), 'PPpp') : 'Unknown time'
        });
      });

      return activities;
    } catch (error) {
      console.error('Error fetching recent activity:', error);
      return null;
    }
  };

  // Fetch AI insights
  const fetchAIInsights = async (institutionId) => {
    try {
      const insightsRef = collection(db, "aiInsights");
      const q = query(
        insightsRef,
        where("institutionId", "==", institutionId),
        orderBy("createdAt", "desc"),
        limit(3)
      );

      const snapshot = await getDocs(q);
      
      if (snapshot.empty) return null;

      // Process AI insights
      const insights = [];
      snapshot.forEach(doc => {
        const data = doc.data();
        insights.push({
          id: doc.id,
          title: data.title,
          description: data.description
        });
      });

      return insights;
    } catch (error) {
      console.error('Error fetching AI insights:', error);
      return null;
    }
  };

  // Fetch upcoming meetings
  const fetchUpcomingMeetings = async (institutionId) => {
    try {
      const now = new Date();
      const meetingsRef = collection(db, "meetings");
      const q = query(
        meetingsRef,
        where("institutionId", "==", institutionId),
        where("date", ">=", now),
        orderBy("date", "asc"),
        limit(3)
      );

      const snapshot = await getDocs(q);
      
      if (snapshot.empty) return null;

      // Process upcoming meetings
      const meetings = [];
      snapshot.forEach(doc => {
        const data = doc.data();
        const meetingDate = data.date ? data.date.toDate() : null;
        
        if (meetingDate) {
          meetings.push({
            id: doc.id,
            title: data.title,
            time: format(meetingDate, 'PPpp'),
            borderColor: getColorForMeetingType(data.type),
            participants: data.participants || []
          });
        }
      });

      return meetings;
    } catch (error) {
      console.error('Error fetching upcoming meetings:', error);
      return null;
    }
  };

  // Fetch general stats
  const fetchGeneralStats = async (institutionId) => {
    try {
      // Upcoming meetings count
      const now = new Date();
      const meetingsRef = collection(db, "meetings");
      const meetingsQuery = query(
        meetingsRef,
        where("institutionId", "==", institutionId),
        where("date", ">=", now)
      );
      const meetingsSnapshot = await getDocs(meetingsQuery);
      const upcomingMeetingsCount = meetingsSnapshot.size;

      // Yesterday's meetings count for comparison
      const yesterday = subDays(now, 1);
      const yesterdayMeetingsQuery = query(
        meetingsRef,
        where("institutionId", "==", institutionId),
        where("date", ">=", yesterday),
        where("date", "<", now)
      );
      const yesterdayMeetingsSnapshot = await getDocs(yesterdayMeetingsQuery);
      const yesterdayMeetingsCount = yesterdayMeetingsSnapshot.size;
      
      const meetingsChange = `${upcomingMeetingsCount > yesterdayMeetingsCount ? '+' : ''}${upcomingMeetingsCount - yesterdayMeetingsCount} from yesterday`;

      // Document workflows
      const workflowsRef = collection(db, "documentWorkflows");
      const workflowsQuery = query(
        workflowsRef,
        where("institutionId", "==", institutionId)
      );
      const workflowsSnapshot = await getDocs(workflowsQuery);
      
      // Count pending workflows
      let pendingWorkflows = 0;
      workflowsSnapshot.forEach(doc => {
        const data = doc.data();
        if (data.status === 'pending' || data.status === 'in_progress') {
          pendingWorkflows++;
        }
      });
      
      const workflowsStatus = `${pendingWorkflows} pending approval`;

      // Active staff
      const staffRef = collection(db, "userProfiles");
      const staffQuery = query(
        staffRef,
        where("institutionId", "==", institutionId)
      );
      const staffSnapshot = await getDocs(staffQuery);
      
      // Count staff by role
      let educators = 0;
      let managers = 0;
      
      staffSnapshot.forEach(doc => {
        const data = doc.data();
        if (data.userRole?.includes('Educator')) {
          educators++;
        } else if (['Principal', 'Deputy Principal', 'HOD'].includes(data.userRole)) {
          managers++;
        }
      });
      
      const staffBreakdown = `${educators} educators, ${managers} managers`;

      // Resource utilization
      const resourcesRef = collection(db, "resources");
      const resourcesQuery = query(
        resourcesRef,
        where("institutionId", "==", institutionId)
      );
      const resourcesSnapshot = await getDocs(resourcesQuery);
      
      // Calculate utilization
      let totalResources = resourcesSnapshot.size;
      let usedResources = 0;
      
      resourcesSnapshot.forEach(doc => {
        const data = doc.data();
        if (data.status === 'in_use' || data.status === 'reserved') {
          usedResources++;
        }
      });
      
      const utilization = totalResources > 0 ? 
        Math.round((usedResources / totalResources) * 100) : 0;
      
      const utilizationPercentage = `${utilization}%`;
      
      // Get last week's utilization for comparison
      const lastWeekUtilization = `+5% from last week`; // Mock data as we don't have historical records

      return {
        upcomingMeetings: upcomingMeetingsCount,
        meetingsChange,
        resourceUtilization: utilizationPercentage,
        utilizationChange: lastWeekUtilization,
        documentWorkflows: workflowsSnapshot.size,
        workflowsStatus,
        activeStaff: staffSnapshot.size,
        staffBreakdown
      };
    } catch (error) {
      console.error('Error fetching general stats:', error);
      return null;
    }
  };

  // Helper function to get color for meeting type
  const getColorForMeetingType = (type) => {
    switch (type) {
      case 'SMT': return 'blue';
      case 'SGB': return 'indigo';
      case 'Department': return 'green';
      case 'Staff': return 'purple';
      case 'PTA': return 'yellow';
      case 'Disciplinary': return 'red';
      default: return 'gray';
    }
  };

  // Generate mock data for sections where real data is missing
  const getMockDashboardData = (existingData) => {
    const mockData = {
      stats: {
        upcomingMeetings: 12,
        meetingsChange: '+2 from yesterday',
        resourceUtilization: '84%',
        utilizationChange: '+5% from last week',
        documentWorkflows: 7,
        workflowsStatus: '3 pending approval',
        activeStaff: 42,
        staffBreakdown: '32 educators, 10 managers'
      },
      attendance: {
        previousDay: 92,
        previousWeek: 89,
        yearAverage: 91,
        trend: 'up'
      },
      subjects: {
        performance: [
          { name: 'Mathematics', performance: 78, grade: 'Grade 12' },
          { name: 'Physical Sciences', performance: 76, grade: 'Grade 12' },
          { name: 'Life Sciences', performance: 82, grade: 'Grade 12' },
          { name: 'English', performance: 85, grade: 'Grade 12' },
          { name: 'History', performance: 79, grade: 'Grade 12' }
        ]
      },
      tasks: {
        inProgress: 15,
        completed: 28,
        overdue: 3
      },
      teachingLearning: {
        expected: 560,
        actual: 532,
        percentage: 95
      },
      assessments: {
        formal: {
          expected: 64,
          actual: 59,
          percentage: 92
        },
        informal: {
          expected: 120,
          actual: 98,
          percentage: 82
        }
      },
      recentActivity: [
        {
          id: 1,
          user: {
            initials: 'TM',
            name: 'Terry Mitchell',
            color: 'blue'
          },
          action: 'created a new document workflow',
          time: 'Today, 10:30 AM'
        },
        {
          id: 2,
          user: {
            initials: 'SK',
            name: 'Sarah King',
            color: 'green'
          },
          action: 'scheduled a staff meeting',
          time: 'Today, 9:15 AM'
        },
        {
          id: 3,
          user: {
            initials: 'RL',
            name: 'Robert Lewis',
            color: 'purple'
          },
          action: 'approved resource request',
          time: 'Yesterday, 4:30 PM'
        },
        {
          id: 4,
          user: {
            initials: 'JD',
            name: 'Jessica Davis',
            color: 'yellow'
          },
          action: 'commented on report',
          time: 'Yesterday, 2:15 PM'
        },
        {
          id: 5,
          user: {
            initials: 'BM',
            name: 'Brian Moore',
            color: 'red'
          },
          action: 'requested equipment',
          time: 'Yesterday, 11:30 AM'
        }
      ],
      aiInsights: [
        {
          id: 1,
          title: 'Resource Optimization',
          description: 'Science lab utilization is 32% below average. Consider rescheduling sessions to optimize resource usage.'
        },
        {
          id: 2,
          title: 'Staff Workload Alert',
          description: 'English department showing signs of meeting overload. Consider redistributing administrative tasks.'
        },
        {
          id: 3,
          title: 'Approval Bottleneck',
          description: 'Document approvals for budget requests are taking 3x longer than other workflows. Review process recommended.'
        }
      ],
      upcomingMeetings: [
        {
          id: 1,
          title: 'Department Heads Sync',
          time: 'Today, 2:00 PM - 3:00 PM',
          borderColor: 'blue',
          participants: [
            { initials: 'MJ', color: 'blue' },
            { initials: 'SK', color: 'green' },
            { initials: 'RL', color: 'purple' },
            { more: 4 }
          ]
        },
        {
          id: 2,
          title: 'Budget Planning Session',
          time: 'Tomorrow, 10:00 AM - 12:00 PM',
          borderColor: 'indigo',
          participants: [
            { initials: 'MJ', color: 'blue' },
            { initials: 'JD', color: 'yellow' },
            { initials: 'BM', color: 'red' }
          ]
        },
        {
          id: 3,
          title: 'Resource Allocation Review',
          time: 'Tomorrow, 3:30 PM - 4:30 PM',
          borderColor: 'green',
          participants: [
            { initials: 'MJ', color: 'blue' },
            { initials: 'SK', color: 'green' },
            { more: 2 }
          ]
        }
      ]
    };

    // Only replace sections where data is missing
    return {
      stats: existingData.stats.upcomingMeetings ? existingData.stats : mockData.stats,
      attendance: existingData.attendance.previousDay ? existingData.attendance : mockData.attendance,
      subjects: existingData.subjects.performance.length > 0 ? existingData.subjects : mockData.subjects,
      tasks: existingData.tasks.inProgress > 0 ? existingData.tasks : mockData.tasks,
      teachingLearning: existingData.teachingLearning.expected > 0 ? existingData.teachingLearning : mockData.teachingLearning,
      assessments: existingData.assessments.formal.expected > 0 ? existingData.assessments : mockData.assessments,
      recentActivity: existingData.recentActivity.length > 0 ? existingData.recentActivity : mockData.recentActivity,
      aiInsights: existingData.aiInsights.length > 0 ? existingData.aiInsights : mockData.aiInsights,
      upcomingMeetings: existingData.upcomingMeetings.length > 0 ? existingData.upcomingMeetings : mockData.upcomingMeetings
    };
  };

  // Loading state
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="animate-spin h-12 w-12 border-4 border-blue-500 rounded-full border-t-transparent"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Mock Data Banner */}
      {usingMockData && (
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 flex items-start">
          <AlertCircle className="h-5 w-5 text-yellow-400 mt-0.5 mr-3" />
          <div>
            <h3 className="text-sm font-medium text-yellow-800">Displaying some mock data</h3>
            <p className="mt-1 text-sm text-yellow-700">
              Some sections of this dashboard are showing mock data because live data is unavailable.
              Add real records to see actual data.
            </p>
          </div>
        </div>
      )}

      <div className="flex flex-col md:flex-row md:items-center md:justify-between">
        <h1 className="text-2xl font-bold text-gray-800">School Management Dashboard</h1>
        <div className="mt-4 md:mt-0 flex space-x-2">
          <select className="border rounded-md px-3 py-2 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500">
            <option>This Week</option>
            <option>This Month</option>
            <option>This Quarter</option>
            <option>This Year</option>
          </select>
          <button className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            Export Report
          </button>
        </div>
      </div>
      
      {/* Attendance Statistics */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-500">Previous Day Attendance</p>
              <p className="text-3xl font-bold mt-1">{dashboardData.attendance.previousDay}%</p>
            </div>
            <div className="w-12 h-12 bg-green-50 rounded-full flex items-center justify-center text-green-600">
              <Users size={24} />
            </div>
          </div>
          <div className="mt-4 flex items-center">
            <span className={`text-sm font-medium flex items-center ${
              dashboardData.attendance.trend === 'up' ? 'text-green-500' : 
              dashboardData.attendance.trend === 'down' ? 'text-red-500' : 'text-gray-500'
            }`}>
              {dashboardData.attendance.trend === 'up' ? '↑' : 
               dashboardData.attendance.trend === 'down' ? '↓' : '→'} Compared to weekly average
            </span>
          </div>
        </div>
        
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-500">Previous Week Attendance</p>
              <p className="text-3xl font-bold mt-1">{dashboardData.attendance.previousWeek}%</p>
            </div>
            <div className="w-12 h-12 bg-blue-50 rounded-full flex items-center justify-center text-blue-600">
              <Calendar size={24} />
            </div>
          </div>
          <div className="mt-4 flex items-center">
            <span className="text-gray-500 text-sm font-medium flex items-center">
              Weekly average attendance rate
            </span>
          </div>
        </div>
        
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-500">Year Average Attendance</p>
              <p className="text-3xl font-bold mt-1">{dashboardData.attendance.yearAverage}%</p>
            </div>
            <div className="w-12 h-12 bg-purple-50 rounded-full flex items-center justify-center text-purple-600">
              <BarChart2 size={24} />
            </div>
          </div>
          <div className="mt-4 flex items-center">
            <span className="text-gray-500 text-sm font-medium flex items-center">
              School year attendance rate
            </span>
          </div>
        </div>
      </div>
      
      {/* Tasks and Subject Performance */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Tasks Card */}
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-semibold mb-4">Tasks Overview</h3>
          <div className="space-y-4">
            <div>
              <div className="flex justify-between items-center mb-1">
                <span className="text-sm font-medium text-gray-800">In Progress</span>
                <span className="text-sm font-medium text-blue-600">{dashboardData.tasks.inProgress}</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2">
                <div 
                  className="bg-blue-600 h-2 rounded-full" 
                  style={{ width: `${Math.min(100, dashboardData.tasks.inProgress/(dashboardData.tasks.inProgress + dashboardData.tasks.completed)*100)}%` }}
                ></div>
              </div>
            </div>
            
            <div>
              <div className="flex justify-between items-center mb-1">
                <span className="text-sm font-medium text-gray-800">Completed</span>
                <span className="text-sm font-medium text-green-600">{dashboardData.tasks.completed}</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2">
                <div 
                  className="bg-green-600 h-2 rounded-full" 
                  style={{ width: `${Math.min(100, dashboardData.tasks.completed/(dashboardData.tasks.inProgress + dashboardData.tasks.completed)*100)}%` }}
                ></div>
              </div>
            </div>
            
            <div>
              <div className="flex justify-between items-center mb-1">
                <span className="text-sm font-medium text-gray-800">Overdue</span>
                <span className="text-sm font-medium text-red-600">{dashboardData.tasks.overdue}</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2">
                <div 
                  className="bg-red-600 h-2 rounded-full" 
                  style={{ width: `${Math.min(100, dashboardData.tasks.overdue/(dashboardData.tasks.inProgress + dashboardData.tasks.completed)*100)}%` }}
                ></div>
              </div>
            </div>
          </div>
          
          <div className="mt-6">
            <h4 className="text-sm font-medium text-gray-500 mb-2">Task Completion Rate</h4>
            <div className="flex items-center">
              <div className="text-3xl font-bold text-gray-800">
                {dashboardData.tasks.completed + dashboardData.tasks.inProgress > 0 
                  ? Math.round((dashboardData.tasks.completed / (dashboardData.tasks.completed + dashboardData.tasks.inProgress)) * 100)
                  : 0}%
              </div>
              <div className="ml-2 text-sm text-gray-500">
                completed
              </div>
            </div>
          </div>
        </div>
        
        {/* Subject Performance Chart */}
        <div className="lg:col-span-2 bg-white rounded-lg shadow p-6">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-lg font-semibold">Subject Performance</h3>
            <div className="flex space-x-1">
              <button className="px-3 py-1 text-sm rounded-md bg-blue-100 text-blue-800">
                Grade 12
              </button>
              <button className="px-3 py-1 text-sm rounded-md hover:bg-gray-100">
                Grade 11
              </button>
              <button className="px-3 py-1 text-sm rounded-md hover:bg-gray-100">
                Grade 10
              </button>
            </div>
          </div>
          
          <div className="h-64 flex items-center justify-center">
            {/* This would be replaced with an actual chart component */}
            <div className="w-full h-full">
              {dashboardData.subjects.performance.map((subject, index) => (
                <div key={index} className="mb-3">
                  <div className="flex justify-between items-center mb-1">
                    <span className="text-sm font-medium">{subject.name}</span>
                    <span className="text-sm">{subject.performance}%</span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full h-2">
                    <div 
                      className={`${
                        subject.performance >= 80 ? 'bg-green-600' : 
                        subject.performance >= 70 ? 'bg-blue-600' : 
                        subject.performance >= 60 ? 'bg-yellow-600' : 
                        'bg-red-600'
                      } h-2 rounded-full`}
                      style={{ width: `${subject.performance}%` }}
                    ></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      
      {/* Teaching & Learning and Assessments */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Teaching & Learning */}
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-semibold mb-4">Teaching & Learning</h3>
          <div className="flex items-center space-x-4 mb-6">
            <div className="w-16 h-16 rounded-full flex items-center justify-center text-2xl font-bold bg-blue-100 text-blue-800">
              {dashboardData.teachingLearning.percentage}%
            </div>
            <div>
              <p className="text-sm text-gray-500">Lesson Hours Completion</p>
              <p className="text-sm font-medium">
                {dashboardData.teachingLearning.actual} of {dashboardData.teachingLearning.expected} hours
              </p>
            </div>
          </div>
          
          <div className="space-y-3">
            <div>
              <div className="flex justify-between items-center text-sm mb-1">
                <span>Expected Hours</span>
                <span className="font-medium">{dashboardData.teachingLearning.expected}</span>
              </div>
              <div className="w-full h-2 bg-gray-200 rounded-full">
                <div className="bg-gray-400 h-2 rounded-full w-full"></div>
              </div>
            </div>
            
            <div>
              <div className="flex justify-between items-center text-sm mb-1">
                <span>Actual Hours</span>
                <span className="font-medium">{dashboardData.teachingLearning.actual}</span>
              </div>
              <div className="w-full h-2 bg-gray-200 rounded-full">
                <div 
                  className="bg-blue-600 h-2 rounded-full" 
                  style={{ width: `${(dashboardData.teachingLearning.actual / dashboardData.teachingLearning.expected) * 100}%` }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        
        {/* Assessments */}
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-semibold mb-4">Assessments Completion</h3>
          
          <div className="space-y-6">
            <div>
              <div className="flex justify-between items-center mb-2">
                <h4 className="text-sm font-medium">Formal Assessments</h4>
                <span className="text-sm font-bold text-green-600">
                  {dashboardData.assessments.formal.percentage}%
                </span>
              </div>
              
              <div className="space-y-2">
                <div className="flex justify-between text-xs text-gray-500">
                  <span>Expected: {dashboardData.assessments.formal.expected}</span>
                  <span>Completed: {dashboardData.assessments.formal.actual}</span>
                </div>
                <div className="w-full h-2 bg-gray-200 rounded-full">
                  <div 
                    className="bg-green-600 h-2 rounded-full" 
                    style={{ width: `${dashboardData.assessments.formal.percentage}%` }}
                  ></div>
                </div>
              </div>
            </div>
            
            <div>
              <div className="flex justify-between items-center mb-2">
                <h4 className="text-sm font-medium">Informal Assessments</h4>
                <span className="text-sm font-bold text-blue-600">
                  {dashboardData.assessments.informal.percentage}%
                </span>
              </div>
              
              <div className="space-y-2">
                <div className="flex justify-between text-xs text-gray-500">
                  <span>Expected: {dashboardData.assessments.informal.expected}</span>
                  <span>Completed: {dashboardData.assessments.informal.actual}</span>
                </div>
                <div className="w-full h-2 bg-gray-200 rounded-full">
                  <div 
                    className="bg-blue-600 h-2 rounded-full" 
                    style={{ width: `${dashboardData.assessments.informal.percentage}%` }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="mt-6 flex items-center justify-between">
            <div className="flex items-center">
              <Book className="h-5 w-5 text-gray-400 mr-2" />
              <span className="text-sm text-gray-600">Total Assessments: {dashboardData.assessments.formal.expected + dashboardData.assessments.informal.expected}</span>
            </div>
            <div className="flex items-center">
              <Award className="h-5 w-5 text-gray-400 mr-2" />
              <span className="text-sm text-gray-600">Completed: {dashboardData.assessments.formal.actual + dashboardData.assessments.informal.actual}</span>
            </div>
          </div>
        </div>
      </div>
      
      {/* AI Insights and Activity */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* AI Insights */}
        <div className="bg-gradient-to-br from-blue-500 to-blue-600 rounded-lg shadow p-6 text-white">
          <div className="flex items-start justify-between mb-4">
            <h3 className="text-lg font-semibold">AI Insights</h3>
            <button className="text-blue-200 hover:text-white text-sm">View All</button>
          </div>
          <div className="space-y-4">
            {dashboardData.aiInsights.map((insight) => (
              <div key={insight.id} className="bg-white bg-opacity-10 rounded-lg p-4">
                <p className="text-sm font-medium">{insight.title}</p>
                <p className="text-sm mt-1 text-blue-100">{insight.description}</p>
              </div>
            ))}
          </div>
        </div>
        
        {/* Recent Activity */}
        <div className="lg:col-span-2 bg-white shadow rounded-lg">
          <div className="px-6 py-5 border-b border-gray-200">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Recent Activity</h3>
          </div>
          <div className="p-6">
            <div className="flow-root">
              <ul className="-mb-8">
                {dashboardData.recentActivity.map((activity, activityIdx) => (
                  <li key={activity.id}>
                    <div className="relative pb-8">
                      {activityIdx !== dashboardData.recentActivity.length - 1 ? (
                        <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                      ) : null}
                      <div className="relative flex items-start space-x-3">
                        <div className="relative">
                          <div className={`h-10 w-10 rounded-full bg-${activity.user.color}-100 flex items-center justify-center ring-8 ring-white`}>
                            <span className={`text-${activity.user.color}-600 font-medium`}>
                              {activity.user.initials}
                            </span>
                          </div>
                        </div>
                        <div className="min-w-0 flex-1">
                          <div>
                            <div className="text-sm">
                              <span className="font-medium text-gray-900">{activity.user.name}</span>
                              {' '}
                              <span className="text-gray-600">{activity.action}</span>
                            </div>
                            <p className="mt-0.5 text-sm text-gray-500">{activity.time}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      
      {/* Upcoming Meetings */}
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="px-6 py-5 border-b border-gray-200 flex justify-between items-center">
          <h3 className="text-lg font-medium leading-6 text-gray-900">Upcoming Meetings</h3>
          <button className="text-sm font-medium text-blue-600 hover:text-blue-500">
            View Calendar
          </button>
        </div>
        <div className="divide-y divide-gray-200">
          {dashboardData.upcomingMeetings.map((meeting) => (
            <div key={meeting.id} className={`p-6 border-l-4 border-${meeting.borderColor}-500`}>
              <div className="flex justify-between items-start">
                <div>
                  <h4 className="text-base font-medium text-gray-900">{meeting.title}</h4>
                  <p className="mt-1 text-sm text-gray-500">{meeting.time}</p>
                </div>
                <div className="flex -space-x-2">
                  {meeting.participants.map((participant, index) => 
                    participant.more ? (
                      <div key={index} className="flex items-center justify-center h-8 w-8 rounded-full bg-gray-200 text-xs font-medium text-gray-700 border-2 border-white">
                        +{participant.more}
                      </div>
                    ) : (
                      <div key={index} className={`h-8 w-8 rounded-full bg-${participant.color}-100 flex items-center justify-center text-${participant.color}-800 text-xs font-medium border-2 border-white`}>
                        {participant.initials}
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;